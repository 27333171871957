<template>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-back-button icon="chevron-back-outline"></ion-back-button>
			</ion-buttons>

			<ion-title>{{ title }}</ion-title>

			<ion-buttons slot="end">
				<ion-button
					@click="dismiss()"
				>
					<ion-icon
						name="close-outline"
					></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<ion-content :fullscreen="true">
		<ion-searchbar
			v-model="keyword"
			placeholder="Buscar..."
		></ion-searchbar>

		<ion-list>
			<ion-item
				button
				lines="none"
				@click="dismiss(city)"
				:key="index"
				v-for="(city, index) in filteredCities"
			>
				<ion-label>{{ city.name }}</ion-label>
				<ion-icon name="flag-outline" slot="start"></ion-icon>
			</ion-item>
		</ion-list>
	</ion-content>
</template>

<script>
	//Componentes Vue
	import {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonIcon,
		IonBackButton,
		IonButton,
		IonLabel,
		IonSearchbar,
		IonList,
		IonItem,
		IonButtons
	} from '@ionic/vue';
	import { defineComponent } from 'vue';

	//Ícones Ionic
	import {
		flagOutline,
		closeOutline,
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"flag-outline": flagOutline,
		"close-outline": closeOutline,
	});

	export default defineComponent({
		name: 'ModalPesquisaCidade',

		data() {
			return {
				keyword: ''
			}
		},

		computed: {
			filteredCities() {
				return this.cities.filter((q) => q.name.toLowerCase().includes(this.keyword.toLowerCase()));
			}
		},

		props: {
			title: {
				type: String,
				default: () => 'Filtrar Cidade'
			},

			cities: {
				type: Array,
				default: () => []
			},

			close: {
				type: Function
			},
		},

		methods: {
			dismiss(city = null) {
				this.close(city);
			}
		},

		components: {
			IonContent,
			IonHeader,
			IonTitle,
			IonToolbar,
			IonIcon,
			IonBackButton,
			IonButton,
			IonLabel,
			IonSearchbar,
			IonList,
			IonItem,
			IonButtons
		}
	});
</script>
