<template>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-back-button icon="chevron-back-outline"></ion-back-button>
			</ion-buttons>

			<ion-title>{{ title }}</ion-title>

			<ion-buttons slot="end">
				<ion-button
					@click="dismiss()"
				>
					<ion-icon
						name="close-outline"
					></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<ion-content :fullscreen="true">
		<ion-searchbar
			v-model="keyword"
			placeholder="Buscar..."
		></ion-searchbar>

		<ion-list v-if="!filtering">
			<ion-item
				button
				lines="none"
				@click="dismiss(customer)"
				:key="index"
				v-for="(customer, index) in filteredCustomers"
			>
				<ion-icon name="person-outline" slot="start"></ion-icon>
				<ion-label>
					{{ customer.business_name }}<br>
					<ion-note>{{ customer.document }}</ion-note>
				</ion-label>
			</ion-item>
		</ion-list>

		<ion-list v-else>
			<ion-item button lines="none" :key="index" v-for="(n,index) in 10">
				<ion-icon name="person-outline" slot="start"></ion-icon>
				<ion-skeleton-text animated></ion-skeleton-text>
			</ion-item>
		</ion-list>
	</ion-content>
</template>

<script>
	//Componentes Vue
	import {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonIcon,
		IonBackButton,
		IonButton,
		IonLabel,
		IonSearchbar,
		IonList,
		IonItem,
		IonButtons,
		IonNote,
		IonSkeletonText
	} from '@ionic/vue';
	import { defineComponent } from 'vue';

	//Ícones Ionic
	import {
		personOutline,
		closeOutline,
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	import {db} from "@/firebase";
	addIcons({
		"person-outline": personOutline,
		"close-outline": closeOutline,
	});

	export default defineComponent({
		name: 'ModalPesquisaCliente',

		data() {
			return {
				customers: [],
				filtering: false,
				keyword: ''
			}
		},

		computed: {
			filteredCustomers() {
				this.filtering = true;
				const c = this.customers
					.filter((q) =>
						q.name.toLowerCase().includes(this.keyword.toLowerCase())
						|| q.business_name.toLowerCase().includes(this.keyword.toLowerCase())
						|| (this.$_.get(q,'document') && q.document.includes(this.keyword))
					)
					.slice(0,50);
				this.filtering = false;
				return c;
			}
		},

		props: {
			title: {
				type: String,
				default: () => 'Filtrar Cliente'
			},

			close: {
				type: Function
			},
		},

		methods: {
			dismiss(customer = null) {
				this.close(customer);
			},

			async userCustomers(user_id) {
				const customersRef = db.collection('customers');
				const usersRef = db.collection('users');

				const sellerCustomers = customersRef
					.where('status', '==', 'A')
					.where('seller', '==', usersRef.doc(user_id))
					.orderBy('name', 'asc')
					.get();

				const supervisorCustomers = customersRef
					.where('status', '==', 'A')
					.where('supervisor', '==', usersRef.doc(user_id))
					.orderBy('name', 'asc')
					.get();

				const managerCustomers = customersRef
					.where('status', '==', 'A')
					.where('manager', '==', usersRef.doc(user_id))
					.orderBy('name', 'asc')
					.get();

				const directorCustomers = customersRef
					.where('status', '==', 'A')
					.where('director', '==', usersRef.doc(user_id))
					.orderBy('name', 'asc')
					.get();

				const [
					sellerQuerySnapshot,
					supervisorQuerySnapshot,
					managerQuerySnapshot,
					directorQuerySnapshot,
				] = await Promise.all([
					sellerCustomers,
					supervisorCustomers,
					managerCustomers,
					directorCustomers,
				]);

				return this.$_.union(
					sellerQuerySnapshot.docs,
					supervisorQuerySnapshot.docs,
					managerQuerySnapshot.docs,
					directorQuerySnapshot.docs
				);
			}
		},

		created() {
			const vm = this;

			if (this.$store.state.usuario.permissions['view_yourself_customers']) {
				vm.filtering = true;
				this.userCustomers(this.$store.state.usuario.id)
					.then(docSnapshots => {
						vm.customers = vm.customers = vm.$_.uniqBy(
							docSnapshots.reduce((ac, docSnapshot) => {
								ac.push({
									id: docSnapshot.id,
									...docSnapshot.data()
								});
								return ac;
							},[]),
							'id'
						)
					})
					.catch()
					.then(() => {
						vm.filtering = false;
					});

			} else if (this.$store.state.usuario.permissions['view_anyone_customer']) {
				vm.filtering = true;
				db.collection('customers')
					.where('status', '==', 'A')
					.orderBy('name', 'asc')
					.get()
					.then(snapshot => {
						vm.customers = snapshot.docs.map(function (doc) {
							return {
								id: doc.id,
								...doc.data()
							}
						})
					})
					.catch()
					.then(() => {
						vm.filtering = false;
					});
			}
		},

		components: {
			IonContent,
			IonHeader,
			IonTitle,
			IonToolbar,
			IonIcon,
			IonBackButton,
			IonButton,
			IonLabel,
			IonSearchbar,
			IonList,
			IonItem,
			IonButtons,
			IonNote,
			IonSkeletonText
		}
	});
</script>
