<template>
	<ion-input
		:value="value"
		ref="input"
		v-bind="$attrs"
	></ion-input>
</template>

<script>
	import { onMounted,	ref } from 'vue';

	//Componentes Ionic
	import {IonInput} from "@ionic/vue";

	import {format, setCursor, event} from "@/packages/v-money/utils";
	import defaults from '@/packages/v-money/options'

	export default {
		name: "CustomInput",

		model: {
			prop: 'value',
			event: 'change'
		},

		props: {
			value: String,
			options: {
				type: Object,
				default: () => defaults
			}
		},

		components: {
			IonInput
		},

		setup(props, { emit }) {
			const input = ref(null);

			onMounted(() => {
				input.value.$el.getInputElement().then(function(el) {
					el.oninput = function (evt) {
						if (!evt.isTrusted)
							return;

						let positionFromEnd = el.value.length - el.selectionEnd
						el.value = format(el.value, props.options)
						positionFromEnd = Math.max(positionFromEnd, props.options.suffix.length) // right
						positionFromEnd = el.value.length - positionFromEnd
						positionFromEnd = Math.max(positionFromEnd, props.options.prefix.length + 1) // left
						setCursor(el, positionFromEnd)

						el.dispatchEvent(event('input'))
						emit('change', el.value);
					}

					el.onfocus = function () {
						setCursor(el, el.value.length - props.options.suffix.length)
					}
				});
			});

			return { input };
		}
	}
</script>
