<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button icon="chevron-back-outline"></ion-back-button>
				</ion-buttons>
				<ion-title>{{ $route.meta.title }}</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<div id="container">
				<ion-list>
					<ion-list-header lines="none">
						<ion-label color="primary">Solicitante</ion-label>
					</ion-list-header>

					<ion-item :class="{'ion-invalid': v$.request.source.$invalid }">
						<ion-label position="stacked">Estabelecimento de Origem</ion-label>
						<custom-select
							v-model.lazy="request.source"
							:disabled="!podeAtualizar"
						>
							<ion-select-option value="Pardinho">Pardinho</ion-select-option>
							<ion-select-option value="Santa Cruz">Santa Cruz</ion-select-option>
						</custom-select>
					</ion-item>
					<validation-error :field="v$.request.source"></validation-error>

					<ion-item :class="{'ion-invalid': v$.requester.name.$invalid }">
						<ion-label position="stacked">Nome</ion-label>
						<ion-input
							@click="openModalPesquisaUsuario"
							v-model.lazy="requester.name"
							readonly
							:disabled="!podeAtualizar"
						></ion-input>
						<div slot="end" v-if="podeAtualizar">
							<ion-button slot="icon-only" @click="limpaSolicitante">
								<font-awesome-icon icon="eraser"/>
							</ion-button>
						</div>
					</ion-item>
					<validation-error :field="v$.requester.name"></validation-error>

					<ion-item :class="{'ion-invalid': v$.requester.email.$invalid }">
                        <ion-label position="stacked">E-mail(s) - Separe os endereços com ; (ponto e vírgula)</ion-label>
						<ion-input
							v-model.lazy="requester.email"
							disabled
						></ion-input>
					</ion-item>
				</ion-list>

				<ion-list>
					<ion-list-header lines="none">
						<ion-label color="primary">Cliente</ion-label>
					</ion-list-header>

					<ion-item lines="none" v-if="podeAtualizar">
						<div style="margin: 0 auto">
							<ion-button @click="toggleCliente">
								{{ request.customer_entry === 'manual' ? 'Preench. Manual' : 'Preench. Automático' }}
							</ion-button>

							<ion-button slot="icon-only" @click="limpaCliente">
								<font-awesome-icon icon="eraser"/>
							</ion-button>
						</div>
					</ion-item>

					<ion-item :class="{'ion-invalid': v$.customer.business_name.$invalid }">
						<ion-label position="stacked">Razão Social</ion-label>
						<ion-input
							@click="openModalPesquisaCliente"
							v-model.lazy="customer.business_name"
							:readonly="request.customer_entry === 'automatic'"
							:disabled="!podeAtualizar"
						></ion-input>
					</ion-item>
					<validation-error :field="v$.customer.business_name"></validation-error>

					<ion-item :class="{'ion-invalid': v$.customer.document.$invalid }">
						<ion-label position="stacked">Documento (CPF/CNPJ)</ion-label>
						<custom-input
							:mask="['###.###.###-##','##.###.###/####-##']"
							v-model.lazy="customer.document"
							:disabled="request.customer_entry === 'automatic'"
						></custom-input>
					</ion-item>

					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-item :class="{'ion-invalid': v$.customer.landline.$invalid }">
									<ion-label position="stacked">Telefone</ion-label>
									<custom-input
										:mask="['(##) #.####-####','(##) ####-####']"
										v-model.lazy="customer.landline"
										:disabled="request.customer_entry === 'automatic'"
									></custom-input>
								</ion-item>
							</ion-col>

							<ion-col>
								<ion-item :class="{'ion-invalid': v$.customer.phone.$invalid }">
									<ion-label position="stacked">Celular</ion-label>
									<custom-input
										:mask="['(##) #.####-####','(##) ####-####']"
										v-model.lazy="customer.phone"
										:disabled="request.customer_entry === 'automatic'"
									></custom-input>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>

					<ion-item :class="{'ion-invalid': v$.customer.email.$invalid }">
						<ion-label position="stacked">E-mail</ion-label>
						<ion-input
							v-model.lazy="customer.email"
							:disabled="request.customer_entry === 'automatic'"
						></ion-input>
					</ion-item>
				</ion-list>

				<ion-list>
					<ion-list-header lines="none">
						<ion-label color="primary">Entrega</ion-label>
					</ion-list-header>

					<ion-item :class="{'ion-invalid': v$.state.name.$invalid }">
						<ion-label position="stacked">Estado</ion-label>
						<ion-input
							@click="openModalPesquisaEstado"
							v-model.lazy="state.name"
							readonly
							:disabled="!podeAtualizar"
						></ion-input>
					</ion-item>
					<validation-error :field="v$.state.name"></validation-error>

					<ion-item :class="{'ion-invalid': v$.request.city.$invalid }">
						<ion-label position="stacked">Cidade</ion-label>
                        <ion-input
                            v-if="state.acronym === 'EX'"
                            v-model.lazy="request.city"
                            :disabled="!podeAtualizar"
                        ></ion-input>
						<ion-input
							@click="openModalPesquisaCidade"
							v-model.lazy="city.name"
							readonly
							:disabled="!podeAtualizar"
                            v-else
						></ion-input>
					</ion-item>
					<validation-error :field="v$.request.city"></validation-error>

					<ion-item :class="{'ion-invalid': v$.request.address.$invalid }">
						<ion-label position="stacked">Endereço</ion-label>
						<ion-input
							v-model.lazy="request.address"
							v-uppercase
							:disabled="!podeAtualizar"
						></ion-input>
					</ion-item>
					<validation-error :field="v$.request.address"></validation-error>
				</ion-list>

				<ion-list>
					<ion-list-header lines="none">
						<ion-label color="primary">Pedido</ion-label>
					</ion-list-header>

					<ion-item :class="{'ion-invalid': v$.request.type.$invalid }">
						<ion-label position="stacked">Tipo de Produto</ion-label>
						<ion-input
							v-model.lazy="request.type"
							:disabled="!podeAtualizar"
						></ion-input>
					</ion-item>
					<validation-error :field="v$.request.type"></validation-error>

					<ion-item :class="{'ion-invalid': v$.request.quantity.$invalid }">
						<ion-label position="stacked">Quantidade</ion-label>
						<ion-input
							v-model.number="request.quantity"
							type="number"
							:disabled="!podeAtualizar"
						></ion-input>
					</ion-item>
					<validation-error :field="v$.request.quantity"></validation-error>

					<ion-item :class="{'ion-invalid': v$.request.pallets_quantity.$invalid }">
						<ion-label position="stacked">Quantidade de Paletes</ion-label>
						<ion-input
							v-model.number="request.pallets_quantity"
							type="number"
							:disabled="!podeAtualizar"
						></ion-input>
					</ion-item>
					<validation-error :field="v$.request.pallets_quantity"></validation-error>

					<ion-item :class="{'ion-invalid': v$.request.weight.$invalid }">
						<ion-label position="stacked">Peso</ion-label>
						<custom-input
							v-model.lazy="request.weight"
							:disabled="!podeAtualizar"
						></custom-input>
					</ion-item>
					<validation-error :field="v$.request.weight"></validation-error>

					<ion-item :class="{'ion-invalid': v$.request.invoice_total.$invalid }">
						<ion-label position="stacked">Total da Nota Fiscal</ion-label>
						<currency-input
							v-model.lazy="request.invoice_total"
							:disabled="!podeAtualizar"
						></currency-input>
					</ion-item>
					<validation-error :field="v$.request.invoice_total"></validation-error>

					<ion-item>
						<ion-label position="stacked">Observações</ion-label>
						<ion-textarea
							v-model.lazy="request.ps"
							type="number"
							placeholder="Ex.: 3 entregas"
							:disabled="!podeAtualizar"
						></ion-textarea>
					</ion-item>

					<template v-if="podeAtualizar">
						<p>
							<ion-note>Os valores da cotação estão sendo elaborados para o endereço conforme solicitado. Se houver alterações, a cotação acima não será considerada.</ion-note>
						</p>

						<ion-button v-if="salvando" color="primary" expand="full" disabled>
							<ion-spinner name="crescent"></ion-spinner>
						</ion-button>
						<ion-button v-else color="primary" expand="full" @click="save">
							Finalizar solicitação&nbsp;<ion-icon name="checkmark-outline"></ion-icon>
						</ion-button>
					</template>
				</ion-list>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
	//Aux
	import {enviaCopiaSolicitacao, toast} from "@/helpers";
	const _ = require('lodash');

	//Componentes Ionic
	import {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonIcon,
		IonLabel,
		IonInput,
		IonItem,
		IonList,
		IonListHeader,
		IonTextarea,
		IonNote,
		IonSpinner,
		IonButton,
		// IonSkeletonText,
		IonBackButton,
		modalController,
		IonSelectOption,
		alertController,
		IonGrid,
		IonRow,
		IonCol
	} from "@ionic/vue";

	//Componentes personalizados
	import ModalPesquisaUsuario from "@/components/ModalPesquisaUsuario";
	import ModalPesquisaCliente from "@/components/ModalPesquisaCliente";
	import ModalPesquisaCidade from "@/components/ModalPesquisaCidade";
	import ModalPesquisaEstado from "@/components/ModalPesquisaEstado";
	import ValidationError from "@/components/ValidationError";
	import CustomInput from "@/components/CustomInput";
	import CustomSelect from "@/components/CustomSelect";
	import CurrencyInput from "@/components/CurrencyInput";

	//Ícones Ionic
	import {
		addOutline,
		checkmarkOutline,
		chevronBackOutline,
		createOutline,
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"add-outline": addOutline,
		"create-outline": createOutline,
		"checkmark-outline": checkmarkOutline,
		"chevron-back-outline": chevronBackOutline,
	});

	//Mixins
	import TitleMixin from "@/mixins/TitleMixin";

	//Firebase
	import {db, Timestamp} from "@/firebase";

	//Validação
	import {useVuelidate} from "@vuelidate/core";
	import {integer, required} from "@vuelidate/validators";
	import {no} from "js-utils-br/validations";

	export default {
		name: "DashboardIndex",

		data() {
			return {
				salvando: false,
				users: [],
				states: [],
				cities: [],
				requests: [],
				carregandoCidades: false,
				carregandoSolicitacao: false,

				request: {
					source: null,
					user: null,
					requester: null,
					customer: null,
					city: null,
					state: null,
					ps: "",
					pallets_quantity: null,
					type: null,
					weight: null,
					quantity: null,
					invoice_total: null,
					customer_entry: 'automatic'
				},

				/* As variáveis abaixo são usadas para armazenar os dados de algumas das referências da solicitação.
				* Como são apenas referências, eu não consigo pegar os dados, então eu tenho um watch configurado
				* para cada referência. Ou seja, quando atualiza a referência, consulta o documento e popula essas variáveis aqui. */
				requester: {
					name: null,
					email: null
				},
				customer: {
					business_name: null,
					phone: null,
					document: null,
					email: null,
					landline: null,
				},
				city: {
					name: null
				},
				state: {
					name: null
				},
			}
		},

		validations() {
            return {
                customer: {
                    business_name: {
                        required
                    },
                    phone: {
                        no
                    },
                    landline: {
                        required
                    },
                    email: {
                        required
                    },
                    document: {
                        required
                    }
                },

                requester: {
                    name: {
                        required
                    },
                    email: {
                        required
                    },
                },

                state: {
                    name: {
                        required
                    }
                },

                request: {
                    address: {
                        required
                    },
                    source: {
                        required
                    },
                    city: {
                        required
                    },
                    type: {
                        required
                    },
                    weight: {
                        no
                    },
                    quantity: {
                        required,
                        integer
                    },
                    pallets_quantity: {
                        required,
                        integer
                    },
                    invoice_total: {
                        required
                    },
                    ps: {
                        no
                    },
                }
            };
		},

		watch: {
			request: {
				handler(newValue) {
					if (newValue && Object.keys(newValue).length === 0 && newValue.constructor === Object)
						this.v$.request.$touch();
				},
				immediate: true,
				// deep: true
			},

			"request.requester"(newValue) {
				const vm = this;

				if (typeof newValue === 'object' && _.get(newValue,'_delegate.type') === 'document') {
					db.collection('users')
						.doc(newValue.id)
						.get()
						.then(function (data) {
							vm.requester = data.data();
							vm.v$.requester.$touch();
						})
						.catch(function (err) {
							toast(err);
						})
						.then(function () {
							//
						});
				} else {
					vm.requester = newValue;
				}
			},

			"request.customer"(newValue) {
				const vm = this;

				if (typeof newValue === 'object' && _.get(newValue,'_delegate.type') === 'document') {
					db.collection('customers')
						.doc(newValue.id)
						.get()
						.then(function (data) {
							vm.customer = data.data();
							vm.v$.customer.$touch();
						})
						.catch(function (err) {
							toast(err);
						})
						.then(function () {
							//
						});
				} else {
					vm.customer = newValue;
				}
			},

			"request.state"(newValue) {
				const vm = this;

				if (typeof newValue === 'object' && _.get(newValue,'_delegate.type') === 'document') {
					db.collection('states')
						.doc(newValue.id)
						.get()
						.then(function (data) {
							vm.state = data.data();
							vm.v$.state.$touch();
						})
						.catch(function (err) {
							toast(err);
						})
						.then(function () {
							//
						});
				} else {
					vm.state = newValue;
				}
			},

			"request.city"(newValue) {
				const vm = this;

				if (typeof newValue === 'object' && _.get(newValue,'_delegate.type') === 'document') {
					db.collection('cities')
						.doc(newValue.id)
						.get()
						.then(function (data) {
							vm.city = data.data();
							// vm.v$.city.$touch();
						})
						.catch(function (err) {
							toast(err);
						})
						.then(function () {
							//
						});
				} else {
					vm.city = newValue;
				}
			},
		},

		mixins: [
			TitleMixin
		],

		computed: {
			podeAtualizar() {
				return (this.operation === 'create' && (this.$store.state.usuario.permissions['create_requests_for_anyone'] || this.$store.state.usuario.permissions['create_requests_for_yourself']))
					|| (this.operation === 'edit' && (this.$store.state.usuario.permissions['update_requests_for_anyone'] || this.$store.state.usuario.permissions['update_requests_for_yourself']));
			}
		},

		methods: {
			async successAlert(title, msg) {
				const alert = await alertController
					.create({
						header: title,
						message: msg,
						buttons: ['OK'],
					});
				await alert.present();
				await alert.onDidDismiss();
			},

			save() {
				if (!this.v$.$invalid) {
					const vm = this;
					const now = Timestamp.fromDate(new Date());
					vm.request.updated_at = now;

					if (this.request.customer_entry === 'manual')
						this.request.customer = {
							business_name: this.customer.business_name,
							document: this.customer.document,
							phone: this.customer.phone,
							landline: this.customer.landline,
							email: this.customer.email,
						}

					if (this.operation === 'edit') {
						this.update(this.$route.params.id);

					} else if (this.operation === 'create') {
						vm.request.created_at = now;

						db.collection('requests')
							.get()
							.then(function(snap) {
								let max = snap.docs.reduce(function(ac, s) {
									const id = parseInt(s.id);
									return id > ac ? id : ac;
								}, 0);

								const new_request_id = ++max > 0 ? max : 1;

								vm.update(new_request_id.toString());
							})
							.catch(function(err) {
								toast("Não foi possível calcular o novo ID. Erro: "+err);
							});
					}

				} else {
					toast("Dados inválidos. Verifique os campos novamente!");
				}
			},

			update(id) {
				const vm = this;

				db.collection('requests')
					.doc(id)
					.set(this.request)
					.then(async function() {
						if (vm.operation === 'edit') {
							toast("Requisição atualizada com sucesso!");

							enviaCopiaSolicitacao({id: id, ...vm.request}, vm.customer, vm.requester, vm.$store.state.usuario, vm.state, vm.city);

						} else if (vm.operation === 'create') {
							enviaCopiaSolicitacao({id: id, ...vm.request}, vm.customer, vm.requester, vm.$store.state.usuario, vm.state, vm.city);

							await vm.successAlert("Solicitação Cadastrada",`CÓD. SOLICITAÇÃO: Nº ${id}!`);

							window.history.back();
						}
					})
					.catch(function(err) {
						toast("Não foi possível criar/atualizar a requisição. Erro: "+err);
					});
			},

			toggleCliente() {
				this.request.customer_entry = this.request.customer_entry === 'manual' ? 'automatic' : 'manual';
			},

			limpaCliente() {
				this.request.customer = {
					business_name: null,
					document: null,
					email: null,
				};
				this.request.city = {name: null};
				this.request.state = {name: null};
				this.request.address = null;
			},

			limpaSolicitante() {
				this.request.requester = {
					name: null,
					email: null
				};
			},

			async openModalPesquisaCidade() {
				const vm = this;

				if (!_.get(vm.state,'ibge_code')) {
					toast(`Estado não selecionado.`);
					return;
				}

				const modal = await modalController
					.create({
						component: ModalPesquisaCidade,
						componentProps: {
							cities: vm.cities,
							close: (data) => modal.dismiss(data),
						},
					});

				modal.onDidDismiss()
					.then(function(data) {
						const id = _.get(data,'data.id');
						if (id) {
							vm.request.city = db.collection(`cities`).doc(id);
						}
					})
					.catch(function(err) {
						toast(err);
					});

				return modal.present();
			},

			async openModalPesquisaEstado() {
				const vm = this;

				const modal = await modalController
					.create({
						component: ModalPesquisaEstado,
						componentProps: {
							states: vm.states,
							close: (data) => modal.dismiss(data),
						},
					});

				modal.onDidDismiss()
					.then(function(data) {
						const id = _.get(data,'data.id');
						if (id) {
							vm.request.city = {name: null};
							const docCustomer = db.collection(`states`).doc(id);

							vm.request.state = docCustomer;

							const queryCities = db.collection('cities')
								.where('state', '==', docCustomer)
								.orderBy('name', 'asc');

							vm.carregandoCidades = true;
							vm.$bind('cities', queryCities)
								.then(function(cities) {
									vm.cities = cities;
								})
								.catch(function (err) {
									toast(err);
								})
								.then(function () {
									vm.carregandoCidades = false;
								});
						}
					})
					.catch(function(err) {
						toast(err);
					});

				return modal.present();
			},

			async openModalPesquisaCliente() {
				const vm = this;
				if (vm.request.customer_entry === 'manual')
					return;

				const modal = await modalController
					.create({
						component: ModalPesquisaCliente,
						componentProps: {
							close: (data) => modal.dismiss(data),
						},
					});

				modal.onDidDismiss()
					.then(function(data) {
						const id = _.get(data,'data.id');
						if (id) {
							vm.request.customer = db.collection(`customers`).doc(id);

							//Se não possui estado de entrega no documento
							vm.request.state = _.get(data.data,'delivery.state') ? data.data.delivery.state : data.data.state;

							//Se não possui cidade de entrega no documento
							vm.request.city = _.get(data.data,'delivery.city') ? data.data.delivery.city : data.data.city;

							//Se não possui endereço de entrega no documento
							vm.request.address = _.get(data.data,'delivery.address') ? data.data.delivery.address : data.data.address;
						}
					})
					.catch(function(err) {
						toast(err);
					});

				return modal.present();
			},

			async openModalPesquisaUsuario() {
				const vm = this;

				const modal = await modalController
					.create({
						component: ModalPesquisaUsuario,
						componentProps: {
							users: vm.users,
							close: (data) => modal.dismiss(data),
						},
					});

				modal.onDidDismiss()
					.then(function(data) {
						const id = _.get(data,'data.id');
						if (id)
							vm.request.requester = db.collection(`users`).doc(id);
					})
					.catch(function(err) {
						toast(err);
					});

				return modal.present();
			},
		},

		created() {
			const vm = this;
			this.operation = this.$route.name === 'solicitacoes.create' ? 'create' : 'edit';

			this.request.source = this.$store.state.usuario.source;
			this.request.user = db.collection(`users`).doc(this.$store.state.usuario.id);

			//Define por padrão, o usuário logado como solicitante
			this.request.requester = db.collection(`users`).doc(this.$store.state.usuario.id);

			if (this.operation === 'edit') {
				this.carregandoSolicitacao = true;
				vm.$bind('request', db.collection('requests').doc(this.$route.params.id))
					.then(function() {
						//
					})
					.catch(function (err) {
						toast(err);
					})
					.then(function () {
						vm.carregandoSolicitacao = false;
					});
			}

			if (this.$store.state.usuario.permissions['create_requests_for_anyone']) {
				let queryUsers = db.collection('users');
				vm.$bind('users', queryUsers.orderBy('name', 'asc'))
					.then(function(users) {
						vm.users = users;
					})
					.catch(function (err) {
						toast(err);
					})
					.then(function () {
						//
					});
			}

			let queryStates = db.collection('states');
			vm.$bind('states', queryStates.orderBy('name', 'asc'))
				.then(function(states) {
					vm.states = states;
				})
				.catch(function (err) {
					toast(err);
				})
				.then(function () {
					//
				});

			vm.$bind('requests', db.collection('requests'))
				.then(function(requests) {
					vm.requests = requests;
				})
				.catch(function (err) {
					toast(err);
				})
				.then(function () {
					//
				});
		},

		setup: () => ({ v$: useVuelidate() }),

		components: {
			IonPage,
			IonContent,
			IonTitle,
			IonToolbar,
			IonButtons,
			IonHeader,
			IonIcon,
			IonLabel,
			IonInput,
			IonItem,
			IonList,
			IonListHeader,
			IonTextarea,
			IonNote,
			IonSpinner,
			IonButton,
			// IonSkeletonText,
			ValidationError,
			CustomInput,
			IonBackButton,
			CustomSelect,
			IonSelectOption,
			CurrencyInput,
			IonGrid,
			IonRow,
			IonCol
		}
	}
</script>

<style scoped>
	ion-grid, ion-col {
		padding: 0;
	}
</style>
