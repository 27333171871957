<template>
	<ion-input
		:value="value"
		ref="input"
		v-bind="$attrs"
	></ion-input>
</template>

<script>
	import { onMounted,	ref } from 'vue';

	//Componentes Ionic
	import {IonInput} from "@ionic/vue";

	/* Implementação baseada no vue-the-mask. Grande parte do código abaixo foi extraído do arquivo directive.js */
	import tokens from 'vue-the-mask/src/tokens';
	import masker from "vue-the-mask/src/masker";

	function event(name) {
		let evt = document.createEvent('Event');
		evt.initEvent(name, true, true);
		return evt;
	}

	export default {
		name: "CustomInput",

		model: {
			prop: 'value',
			event: 'change'
		},

		props: {
			value: [String],
			mask: {
				type: [String, Array],
				default: undefined
			}
		},

		components: {
			IonInput
		},

		setup(props, { emit }) {
			const input = ref(null);

			onMounted(() => {
				input.value.$el.getInputElement().then(function(el) {
					el.oninput = function (evt) {
						if (props.mask  !== undefined) {
							if (!evt.isTrusted)
								return;

							// avoid infinite loop
							/* other properties to try to diferentiate InputEvent of Event (custom)
							InputEvent (native)
							  cancelable: false
							  isTrusted: true

							  composed: true
							  isComposing: false
							  which: 0

							Event (custom)
							  cancelable: true
							  isTrusted: false
							*/

							// by default, keep cursor at same position as before the mask
							let position = el.selectionEnd;

							// save the character just inserted
							let digit = el.value[position-1];
							el.value = masker(el.value, props.mask, true, tokens)

							// if the digit was changed, increment position until find the digit again
							while (position < el.value.length && el.value.charAt(position-1) !== digit)
								position++

							if (el === document.activeElement) {
								el.setSelectionRange(position, position);
								setTimeout(function () {
									el.setSelectionRange(position, position)
								}, 0);
							}

							el.dispatchEvent(event('input'));
						}

						emit('change', el.value);
					}
				});
			});

			return { input };
		}
	}
</script>
